import styled from 'styled-components';
import { Form } from '../../components/form/Form';

interface IProps {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
}

export const PasswordFormPageContainer = styled(Form) <IProps>`
    width: 25rem;
    padding: 1.5rem;
    border-radius: 3.125rem;

    background-color: ${({ theme }) => theme.colors.white};
    padding-bottom: ${({ isLoading }) => isLoading ? '0' : '5rem'};
    min-height: ${({ isError, isSuccess }) => isError || isSuccess ? '25rem' : '32.8rem'};

    gap:2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    >img{
        width: 14.25rem;
        height: 9rem;
    }
`;

export const MessageContainer = styled.div`
    gap: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    > svg {
        font-size: 9rem;
    }

    > .success-icon {
        color: ${({ theme }) => theme.colors.green.primary};
    }

    > .error-icon {
        color: ${({ theme }) => theme.colors.red};
    }

    > p {
        color: ${({ theme }) => theme.colors.gray};
        text-align: center;
    }
    
    p.title {
        font-weight: 600;
        font-size: 1.25rem;
    }
    
    p.subtitle {
        font-weight: 400;
        font-size: 1.1rem;
    }
`;