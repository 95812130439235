export const patterns = {
    email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,

    uppercase: /[A-Z]/,
    lowerAndUpper: /^[a-zA-Z]+$/,
    number: /^[0-9]+$/,

    atLeastOneUppercase: /[A-Z]+/,
    atLeastOneLowercase: /[a-z]+/,
    atLeastOneNumber: /[0-9]+/,

    rfc: /^[A-Za-z]{4}[ |-]{0,1}[0-9]{6}[ |-]{0,1}[0-9A-Za-z]{3}$/,

    phone: /^\+[1-9]{1,2}[0-9]{11}$/,

    // Ten digits phone number
    phoneNoCode: /^[0-9]{10}$/,
}
