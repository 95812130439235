import styled, { css } from 'styled-components';

export interface StandardButtonProps {
    width?: string;
    height?: string;
    padding?: string;
    bgColor?: string;
    color?: string;
    radius?: string;
    margin?: string;
    fontSize?: string;
    fontWeight?: string;
    iconColor?: string;
    iconSize?: string;
    borderRadius?: string;
    boxShadow?: string;
    secondary?: boolean;

    py?: string;
    px?: string;
    pl?: string;
    pr?: string;
    pt?: string;
    pb?: string;
    mx?: string;
    my?: string;
    mb?: string;
    ml?: string;
    mr?: string;
    mt?: string;

    m?: string;
    p?: string;

    alignSelf?: string;
    justifySelf?: string;

    xl?: boolean
}

const XlButton = css`
    width: 12rem;
    height: 3.2rem;
    font-size: 1rem;
`

const SecondaryButtonStyles = css`
    background-color: ${props => props.theme.colors.white};
    border: .125rem solid ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.blue};
    :disabled{
        border: .125rem solid ${props => props.theme.colors.blue + "90"};
        background-color: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.blue};
    }
    svg{
        fill: ${props => props.theme.colors.blue};
    }
`

export const StandardButtonStyles = styled.button <StandardButtonProps>`
    margin: ${props => props.margin};
    padding: ${props => props.padding || '0.5rem 1.4rem'};
    border-radius: ${props => props.radius};
    border-radius: ${props => props.borderRadius || '0.5rem'};
    color: ${props => props.color || props.theme.colors.white};
    border: .125rem solid ${props => props.bgColor || props.theme.colors.blue};
    background-color: ${props => props.bgColor || props.theme.colors.blue};

    div{
        font-weight: ${props => props.fontWeight || '500'};
    }

    svg{
        fill: ${props => props.iconColor || props.theme.colors.white};
        font-size: ${props => props.iconSize || props.fontSize};
    }
    
    :disabled{
        border: .125rem solid transparent;
        background-color: ${props => !props.bgColor ? `${props.theme.colors.blue}90` : `${props.bgColor}90`};
    }

    ${props => props.secondary && SecondaryButtonStyles}

    margin-left: ${props => props.mx};
    margin-right: ${props => props.mx};

    margin-top: ${props => props.my};
    margin-bottom: ${props => props.my};

    padding-left: ${props => props.px};
    padding-right: ${props => props.px};

    padding-top: ${props => props.py};
    padding-bottom: ${props => props.py};

    margin-bottom: ${props => props.mb};
    margin-top: ${props => props.mt};
    margin-left: ${props => props.ml};
    margin-right: ${props => props.mr};
    padding-top: ${props => props.pt};
    padding-bottom: ${props => props.pb};
    padding-left: ${props => props.pl};
    padding-right: ${props => props.pr};

    margin: ${props => props.m};
    padding: ${props => props.p};

    align-self: ${props => props.alignSelf};
    justify-self: ${props => props.justifySelf};

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${props => props.fontSize || '.8rem'};
    ${props => props.xl && XlButton}
    width: ${props => props.width};
    height: ${props => props.height};
`