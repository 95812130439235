import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

const BASE_URL = window._env_.REACT_APP_GENERAL_API_URL

export const AppService = createApi({
    reducerPath: "appService",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        validateToken: builder.query<void, string>({
            query: (token) => ({
                url: "/auth/validate-access-token",
                headers: { Authorization: `Bearer ${token}` },
            }),
        }),
        changePassword: builder.mutation<void, { newPassword: string, token: string }>({
            query: ({ newPassword, token }) => ({
                url: "/auth/reset-password",
                method: "PATCH",
                body: { newPassword },
                headers: { Authorization: `Bearer ${token}` },
            }),
        }),
    }),
    refetchOnMountOrArgChange: true,
})

export const {
    useValidateTokenQuery: useValidateToken,
    useChangePasswordMutation: useChangePassword,
} = AppService;