import { colors } from "../../theme";
import { LoaderProps, LoaderWrapper } from "./Loader.styles";

export const Loader = (props: LoaderProps) => (
    <LoaderWrapper
        viewBox="25 25 50 50"
        {...props}
    >
        <circle r="20" cy="50" cx="50"></circle>
    </LoaderWrapper>
)

export const MyLoader = (props: LoaderProps) => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
        }}
    >
        <Loader
            color={props.color || colors.blue}
            size={props.size || '4rem'}
        />
    </div>
)