import { Provider } from 'react-redux/es/exports';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastOptions } from 'react-toastify/dist/types';
import { ThemeProvider } from 'styled-components';
import { GlobalLayout } from './layouts/GlobalLayout';
import { PasswordFormPage } from './pages/password-form-page/PasswordFormPage';
import store from './redux/store/store';
import { GlobalStyle } from './styles/global.styles';
import theme from './theme';

const toastStyle: React.CSSProperties = {
    borderRadius: "1rem",
    fontSize: ".8rem",
}

const toastOptions: ToastOptions = {
    position: 'bottom-center',
    autoClose: 3000,
    theme: 'dark',
    style: toastStyle,
    transition: Slide,
    hideProgressBar: true,
}

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <ToastContainer
                    {...toastOptions}
                />
                <GlobalStyle />
                <GlobalLayout>
                    <BrowserRouter>
                        <Routes>
                            <Route
                                path="/:token"
                                element={<PasswordFormPage />}
                            />
                            <Route
                                path="*"
                                element={<Navigate to="/invalid-token" replace />}
                            />
                        </Routes>
                    </BrowserRouter>
                </GlobalLayout>
            </ThemeProvider>
        </Provider>
    );
}

export default App;

declare global {
    interface Window {
        _env_:any;
    }
}
