import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiError } from 'react-icons/bi'
import { FaRegSmileWink } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { publicLogo } from '../../assets'
import { Button } from '../../components/button/Button'
import { If } from '../../components/if/If'
import { MyLoader } from '../../components/loader/Loader'
import { MyInput } from '../../components/my-input/MyInput'
import { useChangePassword, useValidateToken } from '../../redux/services/app.service'
import { newPasswordSchema, NewPasswordSchemaType } from '../../schemas'
import * as S from './PasswordFormPage.styles'

export const PasswordFormPage = () => {

    const { token } = useParams() as { token: string }

    const [isCustomError, setIsCustomError] = useState(false)
    const { isLoading: isValidating, isError } = useValidateToken(token)
    const [changePassword, { isLoading: isChanging, isSuccess }] = useChangePassword()

    const methods = useForm<NewPasswordSchemaType>({
        resolver: yupResolver(newPasswordSchema),
    });

    const onSubmit = async (data: NewPasswordSchemaType) => {
        try {
            await changePassword({
                token,
                newPassword: data.password,
            }).unwrap()
        } catch (error) {
            const status = (error as any).status
            console.log(status, error)
            status === 401
                ? setIsCustomError(true)
                : toast.error("Ha ocurrido un error, por favor intenta nuevamente.")
        }
    }

    return (
        <S.PasswordFormPageContainer
            methods={methods}
            isSuccess={isSuccess}
            isLoading={isValidating}
            isError={isError || isCustomError}
            onSubmit={methods.handleSubmit(onSubmit)}
        >
            <If showIf={!isValidating && !isError && !isSuccess && !isCustomError}>
                <img src={publicLogo} alt="Anngel" />
                <MyInput<keyof NewPasswordSchemaType>
                    name="password"
                    type="password"
                    label="Nueva contraseña"
                    placeholder='Nueva contraseña'
                />
                <MyInput<keyof NewPasswordSchemaType>
                    type="password"
                    name="passwordConfirm"
                    label="Confirmar contraseña"
                    placeholder='Confirmar contraseña'
                />
                <Button
                    xl width='100%'
                    loading={isChanging}
                >
                    Confirmar
                </Button>
            </If>
            <If showIf={isValidating && !isError}>
                <MyLoader />
            </If>
            <If showIf={!isValidating && (isError || isCustomError)}>
                <S.MessageContainer>
                    <BiError className='error-icon' />
                    <p className='title'>
                        Lo sentimos, esté link ya no es válido.
                    </p>
                    <p className='subtitle'>
                        Por favor, solicita un nuevo link para restablecer tu contraseña.
                    </p>
                </S.MessageContainer>
            </If>
            <If showIf={!isValidating && isSuccess}>
                <S.MessageContainer>
                    <FaRegSmileWink className='success-icon' />
                    <p className='title'>
                        ¡Tu contraseña ha sido cambiada con éxito!
                    </p>
                    <p className='subtitle'>
                        Ahora puedes iniciar sesión con tu nueva contraseña.
                    </p>
                </S.MessageContainer>
            </If>
        </S.PasswordFormPageContainer>
    )
}
